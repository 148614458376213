export default class Cookie {
    static get(name) {
        const value = localStorage.getItem(name);
        return value;
    }

    static set(name, value) {
        localStorage.setItem(name, value);
    }

    static getJSON(cookieName) {
        const value = JSON.parse(localStorage.getItem(cookieName));
        return value;
    }

    static setJSON(name, jsonObject) {
        const jsonString = JSON.stringify(jsonObject);
        localStorage.setItem(name, jsonString);
    }

    static clearAllCookies() {
        localStorage.clear();
    }
}
