import axios from "axios";

import { MAIN_API_URL, config } from "./api-main-url";
import Cookie from "./cookies-class";
import { Toast, sumErrors } from "./global";

export const login = (username, password, companyId) => {
    const loginMutation = async () => {
        try {
            document.querySelector("#login-btn").innerHTML = `<div class="spinner-border spinner-border"></div>`;
            const response = await axios.post(`${MAIN_API_URL}auth/login`, { username, password, companyId });
            Cookie.set("token", response.data.token);
            Cookie.setJSON("profile", response.data.profile);
            Cookie.setJSON("role", response.data.role);
            Cookie.setJSON("permissions", response.data.permissions);
            Cookie.setJSON("companies", response.data.companies);

            window.location.href = "../pages/dashboard.html";

            return response.data;
        } catch (error) {
            const message = error?.response?.data?.errors || error?.response?.data?.message;
            const typeMessage = typeof message;

            const errors = typeMessage === "string" ? [message] : sumErrors(message);
            errors.forEach((error) => Toast("error", error));
        } finally {
            document.querySelector("#login-btn").innerHTML = "ACCEDI";
        }
    };

    return { loginMutation };
};

export const regenrateToken = (companyId) => {
    const regenrateTokenMutation = async () => {
        try {
            const response = await axios.post(
                `${MAIN_API_URL}auth/regenerate-token`,
                { companyId },
                {
                    headers: config,
                }
            );
            Cookie.set("token", response.data.token);
            Cookie.setJSON("profile", response.data.profile);
            Cookie.setJSON("role", response.data.role);
            Cookie.setJSON("permissions", response.data.permissions);
            Cookie.setJSON("companies", response.data.companies);

            window.location.reload();

            return response.data;
        } catch (error) {
            const message = error?.response?.data?.errors || error?.response?.data?.message;
            const typeMessage = typeof message;

            const errors = typeMessage === "string" ? [message] : sumErrors(message);
            errors.forEach((error) => Toast("error", error));
        }
    };

    return { regenrateTokenMutation };
};

export const logout = () => {
    const logoutMutation = async () => {
        try {
            await axios.post(`${MAIN_API_URL}auth/logout`, {}, { headers: config });
            Cookie.clearAllCookies()

            window.location.href = "/";
            return response.data;
        } catch (error) {
            Cookie.clearAllCookies()

            window.location.href = "/";
        }
    };

    return { logoutMutation };
};
