import axios from "axios";
import Toastify from "toastify-js";
import DataTable from "datatables.net-bs5";
import "datatables.net-buttons-bs5";
import "datatables.net-responsive-bs5";

import Cookie from "./cookies-class";
import { MAIN_API_URL, config } from "./api-main-url";

// Get MultiSelect options
export const fetchSelects = async (selects) => {
    try {
        const response = await axios.get(`${MAIN_API_URL}selects?allSelects=${selects}`, { headers: config });
        return response.data;
    } catch (error) {
        const message = error?.response?.data?.errors || error?.response?.data?.message;
        const typeMessage = typeof message;

        const errors = typeMessage === "string" ? [message] : sumErrors(message);
        errors.forEach((error) => Toast("error", error));
    }
};
// Get MultiSelect options
export const fetchPdfUrl = async (date, guid) => {
    try {
        $("#stampa-modal-save").html(`<div class="spinner-border-sm spinner-border"></div>`);
        const response = await axios.get(`${MAIN_API_URL}maintenances-pdf/download-pdf`, { headers: config, params: { date, guid } });
        return response.data;
    } catch (error) {
        const message = error?.response?.data?.errors || error?.response?.data?.message;
        const typeMessage = typeof message;

        const errors = typeMessage === "string" ? [message] : sumErrors(message);
        errors.forEach((error) => Toast("error", error));
    } finally {
        $("#stampa-modal-save").html(`SALVA`);
    }
};

export const placeholderImage = "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png";
export const initializeTable = (tableElementId, tableOptions, tableButtons) => {
    return new DataTable(`#${tableElementId}`, {
        language: {
            url: "https://cdn.datatables.net/plug-ins/1.13.1/i18n/it-IT.json",
            paginate: {
                first: `<i class="ri-arrow-left-double-line"></i>`,
                last: `<i class="ri-arrow-right-double-line"></i>`,
                next: `<i class="ri-arrow-right-s-line"></i>`,
                previous: `<i class="ri-arrow-left-s-line"></i>`,
            },
        },
        responsive: false,
        autoWidth: true,
        orderCellsTop: false,
        // scrollCollapse: true,
        scrollX: true,
        columnDefs: [{ targets: [-1, -2], orderable: false }],
        layout: {
            topStart: {
                buttons: [tableButtons],
            },
            topEnd: "",
            bottomStart: "info",
            bottomEnd: {
                paging: {
                    type: "full_numbers",
                    boundaryNumbers: true,
                },
            },
        },
        ...tableOptions,
    });
};

export const select2Config = (...args) => {
    return {
        allowClear: true,
        width: "100%",
        bindEvents: false,
        tags: true,
        ...args,
    };
};

export const italyMonths = [
    { id: 1, label: "Gennaio", value: "01" },
    { id: 2, label: "Febbraio", value: "02" },
    { id: 3, label: "Marzo", value: "03" },
    { id: 4, label: "Aprile", value: "04" },
    { id: 5, label: "Maggio", value: "05" },
    { id: 6, label: "Giugno", value: "06" },
    { id: 7, label: "Luglio", value: "07" },
    { id: 8, label: "Agosto", value: "08" },
    { id: 9, label: "Settembre", value: "09" },
    { id: 10, label: "Ottobre", value: "10" },
    { id: 11, label: "Novembre", value: "11" },
    { id: 12, label: "Dicembre", value: "12" },
];

export const YearsOptions = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    // years from 2022 to current year + 5
    for (let i = 2022; i <= currentYear; i++) {
        years.push({ id: i, label: i, value: i });
    }
    return years;
};

export const datePickerConfig = (...args) => {
    return {
        format: "dd/mm/yyyy",
        buttonClass: "btn",
        language: "it",
    };
};

export const companies = [
    {
        value: "1",
        label: "Arca Belts",
        image: "/3.Arca-Belts_RGB (1).webp",
        sidebarImage: "/arca-belts.webp",
    },
    {
        value: "3",
        label: "Arca Pro",
        image: "/3.Arca-Pro_RGB (1).webp",
        sidebarImage: "/arca-pro.webp",
    },
    {
        value: "2",
        label: "Arca Rubber",
        image: "/3.Arca-Rubber_RGB.webp",
        sidebarImage: "/arca-rubber.webp",
    },
    {
        value: "4",
        label: "Arca Mech",
        image: "/3.Arca-Mech_RGB.webp",
        sidebarImage: "/arca-mech.webp",
    },
];

export const sidebarLinks = () => {
    return [
        {
            header: "",
            show: true,
            links: [
                {
                    name: "Dashboard",
                    show: true,
                    link: "/pages/dashboard.html",
                    icon: "ri-dashboard-2-line",
                    subLinks: [],
                },
            ],
        },
        {
            header: "ANAGRAFICHE",
            show:
                GetPermission("all_anagraphics") ||
                GetPermission("all_users") ||
                GetPermission("all_maintenances") ||
                GetPermission("all_employees") ||
                GetPermission("all_vehicles") ||
                GetPermission("all_claims"),
            links: [
                {
                    name: "Clienti",
                    show: GetPermission("all_anagraphics"),
                    link: "/pages/anagrafiche/clienti.html",
                    icon: "ri-group-line",
                    subLinks: [],
                },
                {
                    name: "Claim",
                    show: GetPermission("all_claims"),
                    link: "/pages/anagrafiche/claim.html",
                    icon: "ri-file-copy-2-line",
                    subLinks: [],
                },
                {
                    name: "Fornitori",
                    link: "/pages/anagrafiche/fornitori.html",
                    icon: "ri-caravan-line",
                    subLinks: [],
                },
                {
                    name: "Utenti",
                    show: GetPermission("all_users"),
                    link: "/pages/anagrafiche/utenti.html",
                    icon: "ri-user-line",
                    subLinks: [],
                },
                {
                    name: "Interventi",
                    show: GetPermission("all_maintenances"),
                    link: "/pages/anagrafiche/interventi.html",
                    icon: "ri-mind-map",
                    subLinks: [],
                },
                {
                    name: "Dipendenti",
                    link: "dipendenti",
                    icon: "ri-shield-user-line",
                    show: GetPermission("all_anagraphic_dependants"),
                    subLinks: [
                        {
                            name: "Anagrafiche",
                            show: GetPermission("all_employees"),
                            link: "/pages/anagrafiche/dipendenti/anagrafiche.html",
                        },
                        {
                            name: "Corsi",
                            show: GetPermission("all_courses"),
                            link: "/pages/anagrafiche/dipendenti/corsi.html",
                        },
                        {
                            name: "Tipo Documenti",
                            show: GetPermission("all_documents"),
                            link: "/pages/anagrafiche/dipendenti/tipo-documenti.html",
                        },
                        {
                            name: "Documenti Azienda",
                            show: GetPermission("all_company_documents"),
                            link: "/pages/anagrafiche/dipendenti/documenti-azienda.html",
                        },
                    ],
                },
                {
                    name: "Automezzi",
                    show: GetPermission("all_vichles"),
                    link: "automezzi",
                    icon: "ri-truck-line",
                    subLinks: [
                        {
                            name: "Anagrafiche",
                            show: GetPermission("all_vichles"),
                            link: "/pages/anagrafiche/automezzi/anagrafiche.html",
                        },
                    ],
                },
            ],
        },
        {
            header: "ASSISTENZA",
            show: GetPermission("all_calendar_events") || GetPermission("all_documents"),
            links: [
                {
                    name: "Calendario",
                    show: GetPermission("all_calendar_events"),
                    link: "/pages/assistenza/calendario.html",
                    icon: "ri-calendar-todo-line",
                    subLinks: [],
                },
                {
                    name: "Documenti",
                    show: GetPermission("all_documents"),
                    link: "/pages/assistenza/documenti.html",
                    icon: "ri-folders-line",
                    subLinks: [],
                },
            ],
        },
        {
            header: "CONFIGURAZIONE",
            show: GetPermission("all_parameters") || GetPermission("all_categories") || GetPermission("all_products") || GetPermission("all_calendar_categories"),
            links: [
                {
                    name: "Parametri",
                    show: GetPermission("all_parameters"),
                    link: "/pages/configurazzione/parametri.html",
                    icon: "ri-settings-3-line",
                    subLinks: [],
                },
                {
                    name: "Configurazione",
                    show: GetPermission("all_categories"),
                    link: "/pages/configurazzione/configurazione.html",
                    icon: "ri-equalizer-line",
                    subLinks: [],
                },
                {
                    name: "Prodotti",
                    show: GetPermission("all_products"),
                    link: "/pages/configurazzione/prodotti.html",
                    icon: "ri-git-pr-draft-line",
                    subLinks: [],
                },
                {
                    name: "Calendari",
                    show: GetPermission("all_calendar_categories"),
                    link: "/pages/configurazzione/calendari.html",
                    icon: "ri-calendar-check-line",
                    subLinks: [],
                },
            ],
        },
    ];
};

export const parameterTabs = () => {
    return [
        { id: 1, name: "Tipologia attività", slug: "attivita", active: true },
        { id: 2, name: "Note cantiere", slug: "cantiere", active: false },
        { id: 3, name: "Mezzi d'opera", slug: "opera", active: false },
        {
            id: 4,
            name: "Tipo rifornimento",
            slug: "rifornimento",
            active: false,
        },
        { id: 5, name: "Tipo Livello", slug: "livello", active: false },
        { id: 6, name: "Tipo Fascia", slug: "fascia", active: false },
        { id: 7, name: "Tipo Intervento", slug: "intervento", active: false },
        { id: 8, name: "Tipo Urgenza", slug: "urgenza", active: false },
        { id: 9, name: "Tipo Referenti", slug: "referenti", active: false },
        { id: 10, name: "Tipo Data", slug: "data", active: false },
        {
            id: 11,
            name: "Gestori carburante",
            slug: "carburante",
            active: false,
        },
        {
            id: 12,
            name: "Materiale Da Portare",
            slug: "portare",
            active: false,
        },
        { id: 13, name: "Google Calendar", slug: "calendar", active: false },
        { id: 14, name: "Tipo aziende", slug: "aziende", active: false },
        { id: 15, name: "Lavorazioni", slug: "lavorazioni", active: false },
        { id: 16, name: "Prodotti", slug: "prodotti", active: false },
        { id: 17, name: "Stato", slug: "stato", active: false },
        { id: 18, name: "Tipo Interventi", slug: "interventi", active: false },
        { id: 19, name: "Tipo indirizzi", slug: "indirizzi", active: false },
    ];
};
export const configurazioneTabs = () => {
    return [
        { id: 0, name: "Tipologia Corsi", slug: "corsi", active: true },
        // { id: 1, name: "Tipologia Documenti", slug: "documenti", active: false },
    ];
};

export function setTheme(primary, bg, menu, text) {
    document.documentElement.style.setProperty("--primary-color", primary);
    document.documentElement.style.setProperty("--bs-primary", primary);
    document.documentElement.style.setProperty("--bg-color", bg);

    document.documentElement.style.setProperty("--menu-title", menu);
    document.documentElement.style.setProperty("--text-color-3", text);
}

export const Toast = (type, msg, ...props) => {
    Toastify({
        text: msg,
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: "bottom", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
            background: `${type === "error" ? "var(--danger-color)" : type === "success" ? "var(--success-color)" : "var(--info-color)"}`,
            color: `${type === "error" ? "var(--text-white-color)" : "var(--text-white-color)"}`,
        },
        ...props,
    }).showToast();
};

export function sumErrors(errorObject) {
    let errorArray = [];

    // Iterate through each key in the errorObject
    for (let key in errorObject) {
        // Concatenate the error messages for each key to the errorArray
        errorArray = errorArray.concat(errorObject[key]);
    }

    return errorArray;
}

export function getOptions(options, name) {
    return options?.find((item) => item?.label === name)?.options;
}

export function GetPermission(name) {
    const permission = Cookie.getJSON("permissions");

    const getAccess = permission?.find((item) => item?.permissionName === name)?.access;

    return getAccess;
}

export const generateOrderCode = (totalOrders) => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    const randomNumber = Math.floor(Math.random() * 100);
    const orderCode = `S${String(totalOrders + 1).padStart(3, "0")}-${day}-${month}-${year}-${randomNumber}`;

    return orderCode;
};

// Function to parse the date string to a JavaScript Date object
export function parseDate(dateString) {
    const [datePart, timePart] = dateString?.split(" ");
    const [year, month, day] = datePart?.split("-")?.map(Number);
    const [hours, minutes, seconds] = timePart?.split(":")?.map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
}

export function openFileManger(guid, id) {
    window.open(`https://testassistenza.testingelmo.com/callSManager.php?id=${id}&guid=` + guid.replace(/\./g, ""), "_blank");
}

export async function getFiles(type, guid) {
    try {
        const response = await axios.get(`https://testassistenza.testingelmo.com/callSManager.php?id=getFiles&type=${type}&guid=${guid}`);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const sendEmail = async (data, resetFunction) => {
    try {
        $("#send-email-modal-save").html(`<div class="spinner-border-sm spinner-border"></div>`);
        const response = await axios.post(`${MAIN_API_URL}client-mantenance-report/create`, data, { headers: { ...config } });
        Toast("success", response.data.message, { timer: 3000 });
        resetFunction();
        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.errors || error.response?.data?.message;
        const errors = Array.isArray(errorMessage) ? errorMessage : sumErrors(errorMessage);
        errors.forEach((error) => Toast("error", error));
    } finally {
        $("#send-email-modal-save").html("SALVA");
    }
};